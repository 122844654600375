import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link, PageProps } from "gatsby";
import { Seo } from "../components/seo";
import LandingPageOptions from "../components/landing-page/landing-page-options";
import { LivePreviewWindow } from "../components/live-preview-window";
import { LandingPageMockup } from "../components/landing-page/landing-page-mockup";
import { ExpandButton } from "../components/expand-button";
import NextPageButton from "../components/next-page-button";
import {
  AnimationState,
  useAnimatableLayoutEffect,
} from "../components/animation-helpers/animation-state";

const LandingPage = ({ location }: PageProps): React.ReactElement => {
  const { isOpen, onOpen, onClose } = Chakra.useDisclosure();

  const [currentScale, setCurrentScale] = React.useState(1);

  useAnimatableLayoutEffect(() => {
    if (typeof window !== "undefined") {
      const vh = AnimationState.getViewportHeight();
      setCurrentScale(vh / 1130);
    }
  }, []);

  return (
    <Chakra.Box width="100%">
      <Seo title="Landing Page" location={location} />
      <Chakra.Flex
        width="100%"
        flexDir={{ base: "column", lg: "row" }}
        align="start"
        justify="center"
        position="sticky"
        top="0"
        bottom="0"
        left="0"
        right="0"
        overflowY="hidden"
        height="100%"
      >
        <LandingPageOptions />
        <Chakra.Box
          width="100%"
          m="auto"
          pb={{ base: "50px", lg: "0" }}
          px={{ base: "20px", lg: "0" }}
        >
          <LivePreviewWindow maxWidth="600px" m="auto">
            <LandingPageMockup scale={currentScale} />
          </LivePreviewWindow>
          <Chakra.Box maxWidth="600px" m="auto">
            <ExpandButton onClick={onOpen} />
          </Chakra.Box>
          <Chakra.Modal isOpen={isOpen} onClose={onClose}>
            <Chakra.ModalOverlay />
            <Chakra.ModalContent width="100%" maxWidth="1143px" mx="40px">
              <LandingPageMockup scale={2} />
            </Chakra.ModalContent>
          </Chakra.Modal>
        </Chakra.Box>
      </Chakra.Flex>
      <NextPageButton as={Link} to="/resources">
        Next Step
      </NextPageButton>
    </Chakra.Box>
  );
};

export default LandingPage;
