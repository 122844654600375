import React from "react";
import * as Chakra from "@chakra-ui/react";
import PageScrollButton from "../page-scroll-button";
import { HeadingMedium } from "../heading-medium";
import { BodyText } from "../body-text";
import { FormScrollArea } from "../form-scroll-area";
import ImageInfo from "../image-info";
import BodyInputText from "../body-input-text";
import BodyInputTextarea from "../body-input-textarea";
import IconBlocks from "./icon-blocks";
import LandingPageState from "./landing-page-state-machine";

const LandingPageOptions = () => {
  const scrollAreaRef = React.useRef<HTMLDivElement>();

  const valuePropositionValue = LandingPageState.useGlobalState(
    (gs) => gs.valueProposition
  );
  const subHeaderValue = LandingPageState.useGlobalState((gs) => gs.subHeader);
  const bodyCopyValue = LandingPageState.useGlobalState((gs) => gs.bodyCopy);
  const bodySignOffValue = LandingPageState.useGlobalState((gs) => gs.bodySignOff);
  const callOutValue = LandingPageState.useGlobalState((gs) => gs.callOut);
  const ctaButtonValue = LandingPageState.useGlobalState((gs) => gs.ctaButton);

  return (
    <>
      <Chakra.Flex width="100%" height="100%" flexGrow={1}>
        <Chakra.VStack
          justify="start"
          align="start"
          width="100%"
          height="100%"
          py={{ base: "20px", lg: "80px" }}
          pl={{ base: "20px", lg: "82px" }}
          pr={{ base: "20px", lg: "0" }}
          flexGrow={1}
        >
          <FormScrollArea ref={scrollAreaRef}>
            <HeadingMedium>Landing Page</HeadingMedium>
            <BodyText>
              Landing pages are a quick and easy way to validate new product
              propositions with your audience. This is a proven method that is
              used by the world’s leading companies to test customer appeal and
              interest.
            </BodyText>
            <ImageInfo
              title="Cover Image"
              text="Compelling images will encourage your target audience to engage. Recommended image size: 1,200 x 628 pixels."
              onChange={LandingPageState.setCoverImage}
            />
            <BodyInputText
              title="Landing Page Headline"
              body="A simple statement highlighting why customers should choose your product."
              placeholder="Please Enter"
              value={valuePropositionValue}
              onChange={(e) =>
                LandingPageState.setValueProposition(e.target.value, 90)
              }
              number={90}
            />
            <BodyInputText
              title="Landing Page Sub-Header"
              body="A reinforcing message to support your headline statement."
              placeholder="Please Enter"
              number={150}
              value={subHeaderValue}
              onChange={(e) => LandingPageState.setSubHeader(e.target.value, 150)}
            />
            <BodyInputTextarea
              title="Body Copy"
              body="Provide more detail to connect the audience with the value proposition."
              placeholder="Message"
              number={250}
              value={bodyCopyValue}
              onChange={(e) => LandingPageState.setBodyCopy(e, 250)}
            />
            <IconBlocks />
            <BodyInputTextarea
                title="Copy Sign Off"
                body="Summarise your message. Leave your audience on a positive note."
                placeholder="Message"
                number={250}
                value={bodySignOffValue}
                onChange={(e) => LandingPageState.setBodySignOff(e, 250)}
            />
            <BodyInputTextarea
              title="Call Out"
              body="Give your audience a reason to heed your call-to-action."
              placeholder="Message"
              number={250}
              value={callOutValue}
              onChange={(e) => LandingPageState.setCallOut(e, 250)}
            />
            <BodyInputText
              title="Call to Action"
              body="Encourage people to click with an explicit call-to-action button."
              placeholder="Button Text"
              number={90}
              pb="80px"
              onChange={(e) => LandingPageState.setCtaButton(e.target.value, 90)}
              value={ctaButtonValue}
            />
          </FormScrollArea>
        </Chakra.VStack>
        <PageScrollButton scrollArea={scrollAreaRef} />
      </Chakra.Flex>
    </>
  );
};

export default LandingPageOptions;
