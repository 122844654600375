import React from "react";
import * as Chakra from "@chakra-ui/react";
import LandingPageState from "./landing-page-state-machine";
import ImageInfoMedium from "../image-info-medium";

const IconBlocks = () => {
  const iconBlockValues = LandingPageState.useGlobalState(
    (gs) => gs.iconBlocks
  );

  return (
    <Chakra.Box width="100%" pt="44px">
      <Chakra.Text
        fontSize="14px"
        fontWeight="500"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
      >
        Benefits
      </Chakra.Text>
      <Chakra.Text
        fontSize="14px"
        fontWeight="300"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
        pb="35px"
        maxWidth="480px"
      >
        Outline the key benefits of your product or offer.
      </Chakra.Text>
      <Chakra.SimpleGrid
        width="100%"
        columns={2}
        gap={{ base: "25px", lg: "45px" }}
      >
        {iconBlockValues.map((iconBlock, i) => (
          <Chakra.GridItem>
            <Chakra.HStack width="100%" pb="10px">
              <ImageInfoMedium
                onChange={(e) => LandingPageState.setIconBlockIcon(e, i)}
              />
            </Chakra.HStack>
            <Chakra.HStack width="100%" pb="10px">
              <Chakra.InputGroup width="100%">
                <Chakra.Input
                  placeholder="Enter header"
                  width="100%"
                  borderRadius="2px"
                  height="40px"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="#bababa"
                  _placeholder={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "bold",
                  }}
                  value={iconBlock.header}
                  onChange={(e) =>
                    LandingPageState.setIconBlockHeader(e.target.value, i, 30)
                  }
                />
                <Chakra.InputRightElement>
                  <Chakra.Text fontSize="13px" color="#c8232a">
                      {iconBlock.header ? Math.max(30 - iconBlock.header.length, 0) : 30}
                  </Chakra.Text>
                </Chakra.InputRightElement>
              </Chakra.InputGroup>
            </Chakra.HStack>
            <Chakra.HStack width="100%">
              <Chakra.InputGroup width="100%">
                <Chakra.Textarea
                  placeholder="Enter Sub Header"
                  width="100%"
                  borderRadius="2px"
                  height="95px"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="#bababa"
                  _placeholder={{
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "bold",
                  }}
                  value={iconBlock.subHeader}
                  onChange={(e) =>
                    LandingPageState.setIconBlockSubHeader(e.target.value, i, 45)
                  }
                />
                <Chakra.InputRightElement>
                  <Chakra.Text fontSize="13px" color="#c8232a">
                    {iconBlock.subHeader ? Math.max(45 - iconBlock.subHeader.length, 0) : 45}
                  </Chakra.Text>
                </Chakra.InputRightElement>
              </Chakra.InputGroup>
            </Chakra.HStack>
          </Chakra.GridItem>
        ))}
      </Chakra.SimpleGrid>
    </Chakra.Box>
  );
};

export default IconBlocks;
