import React from "react";
import * as Chakra from "@chakra-ui/react";
import { darken } from "@chakra-ui/theme-tools";
import Expand from "../images/expand.svg";

export const ExpandButton = (props: Chakra.ButtonProps): React.ReactElement => {
  const { children, ...otherProps } = props;

  return (
    <Chakra.Button
      d="inline-flex"
      variant="ghost"
      _hover={{ backgroundColor: "#EEEDEC" }}
      _active={{ backgroundColor: darken("#EEEDEC", 3) }}
      _focus={{ backgroundColor: "#EEEDEC" }}
      borderRadius="6px"
      {...otherProps}
    >
      <Expand />
      <Chakra.Text
        as="span"
        pl="16px"
        fontSize="14px"
        lineHeight="20px"
        letterSpacing="0.11px"
      >
        Expand View
      </Chakra.Text>
    </Chakra.Button>
  );
};
